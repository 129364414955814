<template>
  <validation-observer ref="simpleRules">
    <component>
      <b-card v-if="session > 0">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon
            icon="EditIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Details Pengajuan Diskon Khusus
          </h4>
        </div>
        <b-form class="mt-1" v-if="userDataInfoCount.length < 1">
          <b-row>
            <b-col md="12" class="text-center">
              <h5> Data Tidak Ditemukan! </h5>
            </b-col>
          </b-row>
        </b-form>
         <!-- Form: Personal Info Form -->
        <b-form class="mt-1" v-if="userDataInfoCount.length > 0">
          <b-row>
            <b-col md="2">
              <h6> Jenis Input</h6>
            </b-col>
            <b-col md="2">
              <h6> : {{ this.userDataInfo.trn_type.text }}</h6>
            </b-col>
            <b-col md="2">
              <h6> Nama Proyek</h6>
            </b-col>
            <b-col md="6">
              <h6> : {{ this.userDataInfo.trn_name_project }}</h6>
            </b-col>

            <b-col md="2">
              <h6> Dasar Hitung</h6>
            </b-col>
            <b-col md="2">
              <h6> : {{ this.userDataInfo.trn_dasarhitung.text }}</h6>
            </b-col>
            <b-col md="2">
              <h6> Mitra Usaha</h6>
            </b-col>
            <b-col md="6">
              <h6> : {{ this.userDataInfo.trn_mitrausaha.label }}</h6>
            </b-col>

            <b-col md="2">
              <h6> Tanggal Pengajuan</h6>
            </b-col>
            <b-col md="2">
              <h6> : {{ this.userDataInfo.trn_date_request }}</h6>
            </b-col>
            <b-col md="2">
              <h6> Periode Delivery</h6>
            </b-col>
            <b-col md="6">
              <h6> : {{ this.userDataInfo.trn_periode_start+" s/d "+this.userDataInfo.trn_periode_end }}</h6>
            </b-col>

            <b-col md="2">
              <h6> Program DF</h6>
            </b-col>
            <b-col md="2">
              <h6> : {{ userDataInfo.trn_program_df.text }}</h6>
            </b-col>
            <b-col md="2">
              <h6> Premi</h6>
            </b-col>
            <b-col md="2">
              <h6> : {{ this.userDataInfo.trn_premi.text }}</h6>
            </b-col>
            <b-col md="2">
              <h6> </h6>
            </b-col>
            <b-col md="2">
              <h6> </h6>
            </b-col>

            <b-col md="2">
              <h6> Catatan</h6>
            </b-col>
            <b-col md="6">
              <h6> : {{ userDataInfo.trn_notes }}</h6>
            </b-col>
            <b-col md="2">
              <h6> </h6>
            </b-col>
            <b-col md="2">
              <h6> </h6>
            </b-col>
                            
            <b-col md="2">
              <h6> Nilai Proyek</h6>
            </b-col>
            <b-col md="2">
              <h6> : Rp. {{ vueNumberFormat(this.tbl_sum_jual_req) }},-</h6>
            </b-col>
            <b-col md="2">
              <h6> Diskon diminta (%)</h6>
            </b-col>
            <b-col md="2">
              <h6> : {{ userDataInfo.trn_diskon_request }}%</h6>
            </b-col>                
            <b-col md="2">
              <h6> Diskon diminta (Rp)</h6>
            </b-col>
            <b-col md="2">
              <h6> : Rp. {{ vueNumberFormat(this.tbl_sum_harga_selisih) }},-</h6>
            </b-col>
            
            <b-col md="2">
              <h6> <strong>Total Harga Jual</strong></h6>
            </b-col>
            <b-col md="2">
              <h6> : <strong>Rp. {{ vueNumberFormat(this.tbl_sum_jual_req) }},-</strong></h6>
            </b-col>

            <br>
            <br>

            <b-table-simple
              hover
              caption-top
              responsive
              class="rounded-bottom mb-0"
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>No</b-th>
                  <b-th>No.ACR (Sisa Kuota)</b-th>
                  <b-th>Sisa Kuota</b-th>
                  <b-th>No.ACR</b-th>
                  <b-th>No. Project</b-th>
                  <b-th>Nama Product</b-th>
                  <b-th>Volume (Ton)</b-th>
                  <b-th>Price List (Rp)</b-th>
                  <b-th>Diskon Standart (%)</b-th>
                  <b-th>Harga Jual Standart</b-th>
                  <b-th>Harga Jual diminta</b-th>
                  <b-th>Selisih Harga</b-th>
                  <b-th>Ket Khusus</b-th>
                  <b-th>Tujuan Kirim</b-th>
                  <b-th>Franco</b-th>
                  <b-th>Ket Franco</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(val,idx) in tbl" :key="idx">
                  <b-td>{{ val.trn_dtl_line }}</b-td>
                  <b-td>{{ val.trn_dtl_no_acr }}</b-td>
                  <b-td>{{ val.trn_dtl_sisa_kuota }}</b-td>
                  <b-td>{{ val.trn_dtl_no_acr_input == 'null' ? '' : val.trn_dtl_no_acr_input }}</b-td>
                  <b-td>{{ val.trn_dtl_no_project }}</b-td>
                  <b-td>{{ val.trn_dtl_group_kode }}</b-td>
                  <b-td class="text-left">{{ val.trn_dtl_volume}}</b-td>
                  <b-td class="text-right">{{ vueNumberFormat(val.trn_dtl_pricelist, {}) }}</b-td>
                  <b-td>{{ val.trn_dtl_diskonstandart}}%</b-td>
                  <b-td class="text-right">{{ vueNumberFormat(val.trn_dtl_sellprice, {}) }}</b-td>
                  <b-td class="text-right">{{ vueNumberFormat(val.trn_dtl_sellprice_req, {}) }}</b-td>
                  <b-td class="text-right">{{ vueNumberFormat((val.trn_dtl_sellprice_req - val.trn_dtl_sellprice), {}) }}</b-td>
                  <b-td>{{ val.trn_dtl_desc }}</b-td>
                  <b-td>{{ val.trn_dtl_shipto }}</b-td>
                  <b-td>{{ val.trn_dtl_franco == 'gudang' ? 'Gudang MU' : 'Lainnya' }}</b-td>
                  <b-td>{{ val.trn_dtl_franco_desc }}</b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
              <b-tr>
                <b-td class="text-center" colspan="4"> &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>Total</strong>
                  </b-td>
                  <b-td class="text-left"><strong>{{ tbl_sum_vol }}</strong></b-td>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td class="text-right"><strong>{{ vueNumberFormat(tbl_sum_jual_std, {}) }}</strong></b-td>
                  <b-td class="text-right"><strong>{{ vueNumberFormat(tbl_sum_jual_req, {}) }}</strong></b-td>
                  <b-td class="text-right"><strong>{{ vueNumberFormat(tbl_sum_harga_selisih, {}) }}</strong></b-td>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td></b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
          </b-row>
          <b-row class="mt-2">
            <b-col md="12">
              <h3> <strong>Catatan Approval : </strong></h3> <br/>
            </b-col>
            <b-table-simple
                hover
                caption-top
                responsive
                class="rounded-bottom mb-0"
              >
              <b-tbody >
            <b-col md="12" v-for="(val,idx) in userDataInfo.data_appv" :key="idx">
              
                <b-tr>
                  <b-td>Level</b-td><b-td>:</b-td><b-td>{{ val.hist_level }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Nama</b-td><b-td>:</b-td><b-td>{{ val.nama_approval }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Tanggal Approve</b-td><b-td>:</b-td><b-td>{{ val.hist_date }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Note</b-td><b-td>:</b-td><b-td>{{ val.hist_desc }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>&nbsp;</b-td><b-td>&nbsp;</b-td><b-td>&nbsp;</b-td></b-td>
                </b-tr>
              
              
              <!-- <h6 >
                Nama : {{ val.nama_approval }} <br/> 
                Level : {{ val.hist_level }} <br/>
                Tanggal Approve : {{ val.hist_date }} <br/>
                Note : {{ val.hist_desc }} <br/><br/>
              </h6> -->
              <!-- <b-form-textarea
                id="textarea"
                v-model="hist_desc"
                placeholder="Input Catatan..."
                rows="3"
              ></b-form-textarea> -->
            </b-col>
            </b-tbody>
          </b-table-simple>
            <b-col md="12">&nbsp;</b-col>
            <b-col class="text-right">
              <b-button
                variant="outline-secondary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :to="{ name: 'apps-monitoring-pengajuan-list'}"
              >
                Back
              </b-button>
              <!-- <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="btn_reject()"
              >
                Revisi
              </b-button>
              <b-button
                variant="danger"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="status_detail(true, userDataInfo.trn_code)"
              >
                History
              </b-button> -->
              <b-button v-if="showButton"
                variant="success"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click.prevent="btn_close()"
              >
                Close
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>

      <b-modal id="modal-xl" size="xl" title="Approval Atasan Sales"
        ref="status-modal"
      >
        <b-card>
          <b-row>
            <b-table-simple
              hover
              caption-top
              responsive
              class="rounded-bottom mb-0"
            >
              <b-thead head-variant="light">
                <b-tr class="text-center">
                  <b-th>No</b-th>
                  <b-th>Status</b-th>
                  <b-th>Approval</b-th>
                  <b-th>Level</b-th>
                  <b-th>Tanggal</b-th>
                  <b-th>Catatan</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(val,idx) in tbl_detail_status" :key="idx">
                  <b-td class="text-center">{{ idx+1 }}.</b-td>
                  <b-td>{{ val.status }}</b-td>
                  <b-td>{{ val.approval }}</b-td>
                  <b-td class="text-center">{{ val.app_level }}</b-td>
                  <b-td class="text-center">{{ val.tanggal}}</b-td>
                  <b-td class="text-left">{{ val.catatan }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-row>
        </b-card>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="status_detail(false, '')"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>

    </component>
  </validation-observer>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BButton, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { ref, onMounted } from '@vue/composition-api'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  codeVueMultiBasic, codeVueMultiIcon, codeMultiPush, codeVueSelectionLimit, codeVueDir,
} from './code'
import { codeVariant } from './codetoast'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      trn_code: router.currentRoute.params.trn_code,
      emailValue: '',
      name: '',
      required,
      email,
      tbl: [],
      hist_desc:''
    }
  },
  computed: {
    showButton () {
      return this.userDataInfo.trn_flag != 4 && (this.userDataInfo.appv_role == 'View Sales' || this.userDataInfo.appv_role == 'View All')
    }
  },
  created: function(){
    this.load_table(this.trn_code)
  },
  methods: {
    async load_table(value) {
      const result_table0 = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/tbl_step2`, {trn_code: value})
      console.log(result_table0.data[0][1]);

      this.tbl = result_table0.data[0]
      let tbls = result_table0.data[0]
      let sum_vol = 0, sum_jual_std = 0, sum_jual_req = 0, sum_harga_selisih = 0
      for (let i = 0, len = tbls.length; i < len; i++) {
        // console.log(tbls[i].trn_dtl_volume);
        sum_vol += tbls[i].trn_dtl_volume
        sum_jual_std += tbls[i].trn_dtl_sellprice
        sum_jual_req += tbls[i].trn_dtl_sellprice_req
        // combo_mu.push({ label: mu[i].cust_code_name, value: mu[i].cust_code })
      }
      sum_harga_selisih = (sum_jual_req - sum_jual_std)

      this.tbl_sum_vol = sum_vol
      this.tbl_sum_jual_std = sum_jual_std
      this.tbl_sum_jual_req= sum_jual_req
      this.tbl_sum_harga_selisih= sum_harga_selisih
      // console.log(sum_vol);
    },
    async btn_close(){
      // alert('Close')
      this.$bvModal.msgBoxConfirm('Apakah yakin ingin close data ini?', {
        title: 'Konfirmasi',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      }).then(async value => {
        // alert(value) // YES / NO //
        if (value === true) {
          const params = new URLSearchParams()
          params.append('trn_code', this.userDataInfo.trn_code)
          params.append('hist_type', 'Normal')
          params.append('hist_approval', localStorage.getItem('emp_id'))
          params.append('hist_level', 0)
          params.append('hist_desc', 'Close')
          params.append('trn_flag', '4')
          const result = await axios.post(`${useAppConfig().AppUrl}monitoring_pengajuan_sales/close`, params)
          if (result.data.message != 'Berhasil close data') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Gagal Di-Close. '+result.data.message,
                variant: 'danger',
              },
            })
          } 
          else{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'EditIcon',
                text: 'Data Berhasil Di-Close',
                variant: 'success',
              },
            })
            this.$router.push('/apps/monitoring-pengajuan/list')
          }
        }
      })
    }
  },
  setup() {
    const session = ref([])
    onMounted(async () => {
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })

    const userDataInfoCount = ref({})
    const userDataInfo = ref({})
    const tbl_detail_status = ref([])

    onMounted(async () => {
      // alert(router.currentRoute.params.trn_code)
      const paramsEdit = new URLSearchParams()
            paramsEdit.append('trn_code', router.currentRoute.params.trn_code)
            paramsEdit.append('appv_id', localStorage.getItem('emp_id'))
      const result = await axios.get(`${useAppConfig().AppUrl}monitoring_pengajuan_sales/add_edit?${paramsEdit}`)
      console.log('result : '+ result.data.length);
      if(result.data.length > 0){
        userDataInfoCount.value = result.data
        userDataInfo.value = result.data[0]
      }
      else{
        userDataInfoCount.value = result.data
        userDataInfo.value = result.data
      }
      
    })

    const aktifOptions = [
      { text: 'Ya', value: '0' },
      { text: 'Tidak', value: '1' },
    ]

    const urutanOptions = [
    ]

    const divisiOptions = [

    ]

    const namaOptions = [

    ]

    const levelOptions = [
      '1',
      '2',
      '3',
      '4',
      '5',
    ]

    return {
      session,
      codeVueMultiBasic,
      codeVueMultiIcon,
      codeMultiPush,
      codeVueSelectionLimit,
      codeVueDir,
      aktifOptions,
      userDataInfo,
      userDataInfoCount,
      urutanOptions,
      divisiOptions,
      namaOptions,
      levelOptions,
      codeVariant,
      tbl_detail_status
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>

</style>
